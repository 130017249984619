import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardActions, CardContent, CardMedia, Chip, Grid, Stack, Typography, Tooltip, IconButton, Autocomplete, TextField } from '@mui/material';
import ApiService from '../../_services/ApiService';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import playlist from '../../img/Frame 48096170.png'
import { styled } from '@mui/system';
import NoResults from '../../_helpers/reusablefunctions/NoResults';
import SkeletonCard from '../../_helpers/reusablefunctions/SkeletonCard';
import PlaylistDrawer from './PlaylistDrawer';
import { useHistory } from 'react-router-dom';
import PaginationComponent from '../../_helpers/reusablefunctions/paginationComponent';
import useSnackDispatcher from '../../_helpers/reusablefunctions/snackBarComponent';
import { formSnackbar } from '../../_services/_constants/snackbar.constant';
import SnackbarContainer from '../Snackbar';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import { DragIndicator } from '@mui/icons-material';
import { getGenre } from '../../_services/profile';
import SearchBox from '../../components/reusableComponents/SearchBox';
import GetMoodGenreLanguage from '../../_helpers/reusablefunctions/getMoodGenreLanguage';

const StyledChip = styled(Chip)(({ theme }) => ({
    borderRadius: '10px',
    color: '#545454',
    background: '#fff',
    border: '0px',
    padding: '6px 20px 6px 20px',
    fontSize: '14px',
    '&:hover': {
        backgroundColor: '#ED1C24',
        color: '#fff',
    },
    '&.MuiChip-clickable:focus, &.MuiChip-clickable:hover': {
        backgroundColor: '#ED1C24',
        color: '#fff',
    },
    '&.MuiChip-clickable': {
        '&.Mui-active': {
            backgroundColor: '#ED1C24',
            color: '#fff',
        },
    },
}));
const discoverTabs = [
    { label: 'all', value: ["All Playlist"] },
    { label: 'official', value: ['Official Playlist'] },
    { label: 'trending', value: ['Trending Playlist'] },
]
export default function Discover() {
    const { successSnack, errorSnack, warningSnack, infoSnack } = useSnackDispatcher();
    const history = useHistory()
    const [playlistData, setPlaylistData] = useState([])
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [loader, setLoader] = useState(true);
    const [activeChip, setActiveChip] = useState('0-0');
    const [activeLabel, setActiveLabel] = useState('all');
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(20);
    const [total_count, setTotal_count] = useState(0)
    const [searchInput, setSearchInput] = useState('')

    function playlistCall(value) {
        let payload = value !== 'all' ? { search: value } : { premium_users: 'True', limit, offset: page };
        setLoader(true);
        ApiService.get('allPlaylist', payload)
            .then((response) => {
                if (response.status === 200) {
                    let arrayData = response.data.results;
                    const data = arrayData.sort(function (a, b) {
                        return parseInt(a.playlist_order) - parseInt(b.playlist_order);
                    });
                    setPlaylistData(data);
                    setTotal_count(response?.data?.total_count)
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoader(false);
            });
    }

    const handleChipClick = (i, label, value) => {
        setActiveChip(i)
        setActiveLabel(label)
        playlistCall(label)
    }

    const openDetails = (data) => {
        history.push('/playlist-detail', { playlistData: data })
    }

    const trendingplaylist = (data) => {
        let payload = activeLabel !== "trending"
            ? {
                ...(data?.type_official && { type_official: data.type_official }),
                type_trending: 'trending'
            }
            : {
                type_trending: ''
            };

        infoSnack(activeLabel !== "trending" ? formSnackbar.playlist.playlistTrendingAdding : formSnackbar.playlist.playlistTrendingRemove)
        ApiService.put('createEditPlaylist', payload, { id: data?.playlist_id }).then(res => {
            if (res.status == '200') {
                successSnack(activeLabel !== "trending" ? formSnackbar.playlist.playlistTrendingAdding : formSnackbar.playlist.playlistTrendingRemove)
                playlistCall(activeLabel)
            }
        }).catch(error => {
            errorSnack(formSnackbar.errors.errorMsg)
            console.log(error)
        })
    }

    // ----------------- DRAG AND DROP FUNCTION -------------

    function handleOnDragEnd(result) {
        if (!result.destination) return;
        const items = Array.from(playlistData);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setPlaylistData(items);
        const data = items.map(song => song.playlist_id)
        let dataObj = {
            playlist_ids: data.join(',')
        }
        ApiService.put('playlistTtrendSequence', dataObj).then(res => {
            if (res.status == '200') {
            }
        }).catch(error => {
            errorSnack(formSnackbar.errors.errorMsg)
            console.log(error)
        })
    }

    // --------------- GENERE SEARCH FUNCTION ---------------

    function getSearchList() {
        ApiService.get('searchPlaylist', { 'search': searchInput, limit, offset: page }).then((response) => {
            if (response.status == '200') {
                setPlaylistData(response?.data?.results);
                setTotal_count(response?.data?.total_count)
            }

        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        if (searchInput != null && searchInput.length > 0) {
            let delaySearch;
            delaySearch = setTimeout(() => {
                getSearchList()
            }, 1000);
            return () => clearTimeout(delaySearch)
        } else {
            playlistCall(activeLabel)
        }
    }, [page, limit, searchInput])


    // ----------------Search------------
    const handleSearchInput = (value) => {
        setSearchInput(value)
    }


    const cardContainer = (data, provided) => {
        return (
            <Card className="h-100 position-relative" role="box" tabIndex="0" ref={provided?.innerRef} {...provided?.draggableProps}>
                {activeLabel == "trending" && <Box className='text-center'  {...provided.dragHandleProps} >
                    <DragIndicator />
                </Box>}

                <CardMedia
                    onClick={() => openDetails(data)}
                    component="img"
                    style={{
                        height: '200px',
                        cursor: 'pointer'
                    }}
                    alt={data?.playlist_name}
                    image={data.playlist_cover_image ? process.env.REACT_APP_CREATORURL_MEDIA + 'media/' + data.playlist_cover_image : playlist}
                />
                <CardContent className='pb-5'>
                    <Tooltip title={data?.playlist_name}
                        placement="top"
                        arrow>
                        <Typography variant="body2" className='text-truncate'><strong>Name:</strong> {data.playlist_name}</Typography>
                    </Tooltip>
                    <GetMoodGenreLanguage label="Genre" items={data?.playlist_genre || []} />
                    <GetMoodGenreLanguage label="Mood" items={data?.mood || []} />
                    <GetMoodGenreLanguage label="Language" items={data?.language || []} />
                </CardContent >
                <CardActions className='position-absolute bottom-0' >
                    <Box display={'flex'} justifyContent={'space-between'} width={'100%'} >
                        <Button variant='text'
                            disabled={data?.type_trending == "trending" && activeLabel != 'trending'}
                            sx={{ fontSize: '11px' }}
                            onClick={() => trendingplaylist(data)}
                        >
                            {activeLabel != 'trending' ? 'Mark it trending' : 'Remove from trending'}
                        </Button>
                        {/* <Chip icon={<MusicNoteIcon fontSize="10px" />} label={`${data?.playlist_song?.length} songs`} sx={{ backgroundColor: '#E6EEEF', fontSize: '10px' }} /> */}
                    </Box>
                </CardActions>
            </Card >
        )
    }

    return (
        <Box sx={{ padding: '1rem 1rem 3rem 1rem' }}>
            <Box alignItems={'center'} display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'} gap={2} >
                <h2 style={{ marginLeft: '10px' }}>Discover-Playlist</h2>
                <Button variant='contained' className='gn-actionbtn' onClick={() => setIsDrawerOpen(true)} >
                    Create Official Playlist
                </Button>
            </Box>
            <Box mt={2}>
                <Box className="d-flex align-items-center justify-content-between flex-wrap gap-2  mb-3">
                    <Stack direction="row" spacing={1}>
                        {discoverTabs.map((d, i) =>
                            d.value.map((v, index) => (
                                <StyledChip
                                    key={`${i}-${index}`}
                                    label={v}
                                    component="a"
                                    variant="outlined"
                                    clickable
                                    onClick={() => handleChipClick(`${i}-${index}`, d.label, v)}
                                    className={activeChip === `${i}-${index}` ? 'Mui-active' : ''}
                                />
                            ))
                        )}
                    </Stack>
                    {activeLabel == "all" && <SearchBox handleSearchInput={handleSearchInput} placeholder={'Search'} />}
                </Box>
                <Grid container spacing={2} alignItems="stretch">
                    {loader ?
                        Array.from(new Array(8)).map((item, index) => (<Grid key={index} item xs={12} md={4} lg={3}>
                            <SkeletonCard />
                        </Grid>))
                        :
                        loader === false && playlistData.length === 0 ? (
                            <NoResults />
                        )
                            :
                            activeLabel !== "trending" ? playlistData.map((data, index) => (
                                <Grid key={index} item xs={12} md={4} lg={3} >
                                    {cardContainer(data)}
                                </Grid>
                            ))
                                :
                                <DragDropContext onDragEnd={handleOnDragEnd}>
                                    <Droppable droppableId="characters" direction="horizontal">
                                        {(provided) => (
                                            <Grid container display="flex" {...provided.droppableProps} ref={provided.innerRef} >
                                                {playlistData.map((data, index) => (
                                                    <Draggable key={index} draggableId={index.toString()} index={index}>
                                                        {(provided) => (
                                                            <Grid item xs={12} md={4} lg={3} paddingLeft={3} paddingTop={3} >
                                                                {cardContainer(data, provided)}
                                                            </Grid>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </Grid>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                    }
                </Grid>
                <PlaylistDrawer type="create" isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
                <PaginationComponent setLimit={setLimit} setPage={setPage} limit={limit} total_count={total_count} page={page} />
                <SnackbarContainer />
            </Box>
        </Box >
    )
} 
