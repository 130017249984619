import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Card, CardActions, CardContent, CardMedia, Chip, Grid, Stack, Typography, Tooltip, IconButton, Tab } from '@mui/material';
import playlist from '../../img/Frame 48096170.png'
import { useLocation, useHistory } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddSong from './AddSong';
import ApiService from '../../_services/ApiService';
import useSnackDispatcher from '../../_helpers/reusablefunctions/snackBarComponent';
import { formSnackbar } from '../../_services/_constants/snackbar.constant';
import EditIcon from '@mui/icons-material/Edit';
import SnackbarContainer from '../Snackbar';
import PlaylistDrawer from './PlaylistDrawer';
import PlaylistPlatforms from "./playlistPlatforms";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import PlaylistAddSong from './PlaylistAddSong';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteModal from './DeleteModal';

export default function PlaylistDetail() {
    const { successSnack, errorSnack, warningSnack, infoSnack } = useSnackDispatcher();
    const location = useLocation();
    const history = useHistory();
    const ref = useRef();
    const getPlaylistData = location?.state?.playlistData
    const [playlistData, setPlaylistData] = useState([]);
    const [loader, setLoader] = useState(true)
    const [openSongModal, setOpenSongModal] = useState(false)
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [tabValue, setTabValue] = useState(getPlaylistData?.type_official &&
        (getPlaylistData?.type_trending || getPlaylistData?.type_trending === null || getPlaylistData?.type_trending === "") ? '1' : '2')
    const [allPlatfromList, setAllPlatformList] = useState([])
    const [playlistSongLibrary, setPlaylistSongLibrary] = useState([])
    const [getPlaylistList, setGetPlaylistList] = useState()
    const accountTabs = [
        { id: 1, tabActiveId: "1", tabTitle: 'Song', tabType: 'song' },
        { id: 2, tabActiveId: "2", tabTitle: 'Platform', tabType: 'platform' }]
    const handleRouteBack = () => {
        history.push('/discover-playlist')
    }
    function handleTabChange(e, newValue) {
        setTabValue(newValue)
    }
    function getSingleSong() {
        setLoader(true)
        ApiService.get('getSinglePlaylist', { id: getPlaylistData.playlist_id }).then(res => {
            setLoader(false)
            if (res.status === 200 || res.status === 201) {
                setGetPlaylistList(res.data.data[0])
                setPlaylistData(res.data.data[0].song)
                setPlaylistSongLibrary((res.data.data[0].playlist_releated_platform).reverse())
            }
        }).catch(error => {
            console.log(error)
        })
    }
    function getAllPlatform() {
        ApiService.get('streamingPlatforms').then(res => {
            setLoader(false)
            if (res.status === 200 || res.status === 201) {
                setAllPlatformList(res.data.data)
            }
        }).catch(error => {
            console.log(error)
        })
    }
    useEffect(() => {
        getSingleSong()
        getAllPlatform()
    }, [])
    const removeSongFromPlayList = (songId) => {
        const dataObj = { song_ids: songId.toString() }
        infoSnack(formSnackbar.playlist.songRemoving)
        ApiService.post('removeSongPlaylist', dataObj, getPlaylistData?.playlist_id)
            .then(function (response) {
                if (response.status === 200 || response.status === 201) {
                    const removedList = playlistData?.filter(d => d.id !== songId)
                    setPlaylistData(removedList)
                    successSnack(formSnackbar.playlist.songRemove)
                }
            })
            .catch(function (error) {
                errorSnack(formSnackbar.errors.errorMsg)
            })
    }


    const deletePlaylistHanlder = (id) => {
        ApiService.delete('deleteOfficialPlaylist', { id: id }).then((response) => {
            successSnack(formSnackbar.playlist.playlistDelete)
            handleRouteBack()
        }).catch(error => {
            errorSnack(formSnackbar.errors.errorMsg)
            console.log(error)
        })
    }

    // ------------------------- PLATFORM CODE START HERE ---------

    function addnewplatform() {
        setPlaylistSongLibrary([{}, ...playlistSongLibrary])
    }

    return (
        <Box sx={{ padding: '1rem 1rem 3rem 1rem' }}>
            <Box alignItems={'center'} display={'flex'} justifyContent={'space-between'} >
                <Button className='gap-3 text-black' onClick={handleRouteBack}><ArrowBackIcon /> {getPlaylistData?.playlist_name}</Button>
            </Box>
            <Box mt={2} mb={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} lg={3} >
                        <Card className="" role="box" tabIndex="0">
                            <CardMedia
                                component="img"
                                style={{
                                    height: '200px',
                                }}
                                image={getPlaylistList?.playlist_cover_image ? process.env.REACT_APP_CREATORURL_MEDIA + 'media/' + getPlaylistList?.playlist_cover_image : playlist}
                            />
                            <CardContent className='pb-0' >
                                <Typography variant="body2"><strong>Name:</strong> {getPlaylistList?.playlist_name}</Typography>
                                <Box className='d-flex align-items-center gap-2' >
                                    <Typography variant="body2"><strong>Genre:</strong></Typography>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1 }}>
                                        {getPlaylistList?.playlist_genre?.map((genre) => (
                                            <Chip key={genre} label={genre} sx={{ backgroundColor: '#E6EEEF' }} />
                                        )) || null}
                                    </Box>

                                </Box>
                                <Box className='d-flex align-items-center gap-2' >
                                    <Typography variant="body2"><strong>Mood:</strong></Typography>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1 }}>
                                        {getPlaylistList?.mood?.map((mood) => (
                                            <Chip key={mood} label={mood} sx={{ backgroundColor: '#E6EEEF' }} />
                                        )) || null}
                                    </Box>
                                </Box>
                                <Box className='d-flex align-items-center gap-2' >
                                    <Typography variant="body2"><strong>Language:</strong></Typography>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1 }}>
                                        {getPlaylistList?.language?.map((language) => (
                                            <Chip key={language} label={language} sx={{ backgroundColor: '#E6EEEF' }} />
                                        )) || null}
                                    </Box>
                                </Box>


                            </CardContent >
                            <CardActions>
                                {getPlaylistData?.type_official == "official" ? <Box className="text-end w-100">
                                    <Tooltip title='Edit Playlist' arrow>
                                        <IconButton onClick={() => setIsDrawerOpen(true)} >
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title='Edit Playlist' arrow>
                                        <IconButton onClick={() => ref.current.handleOpen()} >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box> : null}
                            </CardActions>
                        </Card >
                    </Grid>

                    <Grid item xs={12} md={8} lg={9} >
                        <TabContext value={tabValue}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList
                                    scrollButtons="auto"
                                    variant="scrollable"
                                    orientation="horizontal"
                                    scroller='true'
                                    sx={{
                                        '& .Mui-selected': {
                                            color: '#E41116 !important',
                                        },
                                        '& .MuiTabs-indicator': {
                                            backgroundColor: '#E41116',
                                        },
                                    }}
                                    onChange={handleTabChange}
                                    aria-label="lab API tabs example">
                                    {getPlaylistData?.type_official &&
                                        (getPlaylistData?.type_trending || getPlaylistData?.type_trending === null || getPlaylistData?.type_trending === '') ? accountTabs?.map((item, i) => {
                                            return (
                                                <Tab sx={{ fontSize: '13px' }} label={item.tabTitle} key={i} value={item.tabActiveId} />)
                                        }) : accountTabs?.slice(1, 2).map((item, i) => {
                                            return (
                                                <Tab sx={{ fontSize: '13px' }} label={item.tabTitle} key={i} value={item.tabActiveId} />)
                                        })}
                                </TabList>
                            </Box>

                            {(() => {
                                switch (tabValue) {
                                    case '1':
                                        return (<TabPanel className='px-0 pt-2' value='1'>
                                            {getPlaylistData?.type_official == 'official' && (<Box textAlign={'end'}>
                                                <Button variant='contained' className='gn-actionbtn' onClick={() => setOpenSongModal(true)} >Add Song</Button>
                                            </Box>)}
                                            <PlaylistAddSong loader={loader} playlistData={playlistData} removeSongFromPlayList={removeSongFromPlayList} />
                                        </TabPanel>);
                                    case '2':
                                        return (<TabPanel className='px-0 pt-2' value='2'>
                                            {getPlaylistData?.type_official == 'official' && <Box textAlign={'end'}>
                                                <Button variant='contained' className='gn-actionbtn' onClick={addnewplatform} > Add Platform</Button>
                                            </Box>}
                                            {playlistSongLibrary && playlistSongLibrary.map((card, index) => (
                                                <Box key={card.id} index={index}>
                                                    <PlaylistPlatforms allPlatfromList={allPlatfromList}
                                                        setAllPlatformList={setAllPlatformList} getSingleSong={getSingleSong}
                                                        playlistSongLibrary={card} setPlaylistSongLibrary={setPlaylistSongLibrary} index={index}
                                                        allplaylistSongLibrary={playlistSongLibrary} getPlaylistData={getPlaylistData} />
                                                </Box>
                                            ))}

                                        </TabPanel>);
                                }
                            })()}
                        </TabContext>
                    </Grid>

                </Grid>
            </Box>

            <PlaylistDrawer isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} type="edit" getSingleSong={getSingleSong} getPlaylistList={getPlaylistList} />
            <AddSong openSongModal={openSongModal} setOpenSongModal={setOpenSongModal} playlistSongData={playlistData} getSingleSong={getSingleSong} />
            <SnackbarContainer />
            <DeleteModal cardId={getPlaylistData.playlist_id} deleteHandler={deletePlaylistHanlder} ref={ref} />
        </Box >
    )
} 
