
import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, List, ListItem, ListItemText, Menu, MenuItem, Paper, Typography, useMediaQuery } from '@mui/material';
import TableComponent from '../importRevenue/Tablecomponent';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ApiService from '../../_services/ApiService';
import { getSongStatusBgColor } from '../../_helpers/reusablefunctions/getColor';
import SongConfirmationDialog from './SongConfirmationDialog';
import { setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import SnackbarContainer from '../Snackbar';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
export default function SongpitchView() {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory();
  const songDataView = location?.state?.state || ''
  const [open, setOpen] = useState(false);
  const [statusValue, setStatusValue] = useState('');
  const [getSongDetail, setGetSongDetail] = useState()
  const [rejectComment, setRejectComment] = useState('')
  const userData = useSelector((state) => state.userData.userData);
  const releaseSongList = useSelector((state) => state.releaseSongList.releaseSongList)
  const songpitch = [
    { breakValue: 'false', label: 'Title/Album', value: songDataView?.songData?.album_name || '--' },
    { breakValue: 'false', label: 'Pitch Date', value: songDataView?.songData?.pitching_date || '--' },
    { breakValue: 'false', label: 'Release Date', value: songDataView?.songData?.release_date || '--' },
    { breakValue: 'false', label: 'Label Name', value: songDataView?.songData?.label || '--' },
    { breakValue: 'false', label: 'Genre', value: songDataView?.songData?.genre || '--' },
    { breakValue: 'false', label: 'Language', value: songDataView?.songData?.language || '--' },
    { breakValue: 'false', label: 'Singer', value: songDataView?.songData?.singer || '--' },
    { breakValue: 'false', label: 'Lyricist', value: songDataView?.songData?.lyricist || '--' },
    { breakValue: 'false', label: 'Music Producer', value: songDataView?.songData?.music || '--' },
    { breakValue: 'false', label: 'ISRC Code', value: songDataView?.songData?.isrc_code || '--' },
    { breakValue: 'false', label: 'UPC Code: ', value: songDataView?.songData?.upc_code || '--' },
    { breakValue: 'false', label: 'Status', value: songDataView?.songData?.pitching_status || '--' },
    { breakValue: 'true', label: 'Drive Link', value: songDataView?.songData?.drive_link || '--' },
    { breakValue: 'true', label: 'Artist Overview', value: songDataView?.songData?.artist_overview || '--' },
    { breakValue: 'true', label: 'About Song', value: songDataView?.songData?.song_description || '--' },
  ]

  function rejectPitch(e) {
    e.preventDefault()
    setOpen(!open)
    setStatusValue('Reject')
  }

  function approvalPitch(e) {
    e.preventDefault()
    // const getSongDetailList = releaseSongList?.results?.find(d => d.song_id == songDataView?.songData?.song_id)
    // setGetSongDetail(getSongDetailList)
    setOpen(!open)
    setStatusValue('Approved')
  }

  function onchangeRjectHandler(e) {
    setRejectComment(e.target.value)
  }

  function excelDownload() {

    const filteredData = songpitch.filter(item => item.label !== 'Status' && item.label !== 'Pitch Date');

    const headers = ['Label', 'Value'];
    const data = filteredData.map(item => [item.label, item.value]);

    // Combine headers and data
    const worksheetData = [
      headers,
      ...data
    ];
    // Convert array of arrays to worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Add table format to the worksheet
    XLSX.utils.sheet_add_aoa(worksheet, [[songDataView?.songData?.song_name, '']], { origin: 'A1' });
    worksheet['!ref'] = XLSX.utils.encode_range({
      s: { c: 0, r: 0 },
      e: { c: 2, r: data.length },
    });

    // Add table style
    worksheet['!cols'] = [{ wch: 20 }, { wch: 10 }, { wch: 20 }]; // Column widths
    worksheet['!rows'] = [{ hpx: 20 }]; // Row height

    // Write the workbook to a binary string
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Create a Blob from the binary string
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

    // Save the Blob to the user's computer
    saveAs(blob, songDataView?.songData?.song_name + '.xlsx');
  }

  function approvePitch() {

    let payload = {
      isrc_code: songpitch?.songData?.isrc_code || '',
      upc_code: songpitch?.songData?.upc_code || '',
      approver_user_id: userData?.id,
      pitching_status: statusValue,
      comment: rejectComment
    }
    ApiService.put('songPitchStatus', payload, '',songDataView.songData?.id).then((res) => {
      if (res.status == 200) {
        if (statusValue == "Approved") {
          excelDownload()
        }
        setOpen(false)
        dispatch(setSnackBar(true));
        dispatch(setSnackBarVariant(`${statusValue == 'Approved' ? 'success' : 'error'}`));
        dispatch(setSnackBarMessage(`${statusValue == 'Approved' ? 'Song pitch has been successfully submitted.' : 'Song pitch has been rejected. '}`));
        setTimeout(() => dispatch(setSnackBar(false)), 2000);
        history.push('/songpitch/')
      }
    }).catch((error) => {
      console.log('error')
    })
  }

  function backToHistory() {
    history.push('/songpitch')
  }

  return (
    <Box className="container-padding">
      <Box className="d-flex align-items-center justify-content-between mb-3">
        <button className="icononly-btn" onClick={() => backToHistory()}><ArrowBackIcon /> Back</button>
      </Box>
      <Paper className="shadow-table" sx={{ width: '100%', mb: '3rem' }}>
        <Box p={3}>
          <Grid container sx={{ background: ' #F9F9F9', borderRadius: '10px', padding: '15px' }}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 500 }}>Basic Information</Typography>
              <Grid container spacing={2} mt={1}>
                {songpitch.map((item, index) => (
                  <Grid item xs={12} sm={item.breakValue == "true" ? 12 : 6} key={index}>
                    <Typography variant="body1" sx={{ fontSize: '14px' }}>
                      <strong>{item.label + ":"}</strong>
                      <Typography variant='span' className={item.label == "Status" ? 'rounded-pill px-3 py-2 text-center text-white ms-2' : ''} sx={{ background: item.label == "Status" && getSongStatusBgColor(item?.value), padding: '10px', color: '#545454', fontWeight: 500 }}>{item.value}</Typography>
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          {songDataView?.songData?.pitching_status == "Reject" || songDataView?.songData?.pitching_status == "Approved" ? null : <Box display="flex" justifyContent="flex-end" mt={3} gap={2}>
            <Button onClick={(e) => rejectPitch(e)} className='textBtn'>
              Reject
            </Button>
            <Button onClick={(e) => approvalPitch(e)} variant="contained" className='gn-actionbtn'>
              Approve
            </Button>
          </Box>}
        </Box>
      </Paper>
      <SongConfirmationDialog onchangeRjectHandler={onchangeRjectHandler} open={open} setOpen={setOpen} approvePitch={approvePitch} statusValue={statusValue} />
      <SnackbarContainer />
    </Box>
  );
}