import React, { useRef, useState, useEffect } from 'react';
import { Checkbox, Drawer, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useDispatch, useSelector } from 'react-redux';
import { getGenre } from '../../_services/profile/getGenre.service';
import ApiService from '../../_services/ApiService';
import { setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { formSnackbar } from '../../_services/_constants/snackbar.constant';
import { useLocation } from 'react-router-dom';
import useSnackDispatcher from '../../_helpers/reusablefunctions/snackBarComponent';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function PlaylistDrawer({ isDrawerOpen, setIsDrawerOpen, type, getPlaylistList, getSingleSong }) {
  const { successSnack, errorSnack, warningSnack, infoSnack } = useSnackDispatcher();
  const dispatch = useDispatch()
  const location = useLocation();
  const smallViewport = useMediaQuery('(max-width:768px)');
  const [selectedFile, setSelectedFile] = useState(getPlaylistList ? getPlaylistList?.playlist_cover_image : null);
  const [playlistName, setPlaylistName] = useState(getPlaylistList ? getPlaylistList?.playlist_name : '');
  const [playlistNameErr, setPlatlistNameErr] = useState(null)
  const [playlistNameErrMsg, setPlatlistNameErrMsg] = useState('')
  const [selectedPrivacy, setSelectedPrivacy] = useState(getPlaylistList ? getPlaylistList?.privacy_status === true ? 'public' : 'private' : 'public');
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [selectedMood, setSelectedMood] = useState([]);
  const [genres, setGeners] = useState([])
  const [mood, setMood] = useState([])
  const [language, setLanguage] = useState([])
  const [fileError, setFileError] = useState('')
  const [disableBtn, setBtnDisable] = useState(getPlaylistList ? getPlaylistList?.playlist_name && getPlaylistList?.playlist_name.length > 0 ? false : true : true)
  const [fileSizeError, setFileSizeError] = useState(false);
  const [playlistSlug, setPlaylistSlug] = useState(getPlaylistList ? getPlaylistList?.slug : '');
  const allowedExtensions = ['.png', '.jpg', '.jpeg', '.svg', '.gif'];

  useEffect(() => {
    if (getPlaylistList) {
      setSelectedFile(getPlaylistList.playlist_cover_image || null);
      setPlaylistName(getPlaylistList.playlist_name || '');
      setSelectedPrivacy(getPlaylistList.privacy_status ? 'public' : 'private');
      setBtnDisable(!(getPlaylistList.playlist_name && getPlaylistList.playlist_name.length > 0));
      setPlaylistSlug(getPlaylistList.slug || getPlaylistList.playlist_name);
    }
  }, [getPlaylistList]);


  const handleClose = () => {
    setIsDrawerOpen(false);
  }

  const fileInputRef = useRef(null);
  const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB in bytes

  const fileValidation = (file) => {
    const fileExtension = '.' + file.name.split('.').pop();
    if (!allowedExtensions.includes(fileExtension.toLowerCase())) {
      setFileError('Please upload a valid file.');
      setSelectedFile('');
      return;
    } if (file.size > MAX_FILE_SIZE) {
      setFileSizeError(true);
      setFileError('File size exceeds 2MB limit.');
      setSelectedFile('');
      return;
    } else {
      setFileSizeError(false);
      setSelectedFile(file);
      setFileError('')
    }
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      fileValidation(file)
    }
  };
  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      fileValidation(file)
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  function apiMoodGenreLanguage() {
    getGenreList()
    getMoodList()
    getLanguageList()
  }

  useEffect(() => {
    if (getPlaylistList && type == 'edit') {
      apiMoodGenreLanguage()
    }
    if (type != "edit") {
      apiMoodGenreLanguage()
    }
  }, [getPlaylistList])
  const getMoodList = () => {
    ApiService.get('getMood').then(function (response) {
      setMood(response.data.data)
      if (getPlaylistList && getPlaylistList?.mood.length > 0) {
        const matchedMood = response.data.data
          .filter((item) => getPlaylistList?.mood.includes(item.mood))
          .map((item) => item.id);
        setSelectedMood(matchedMood)
      }
    }).catch(function (error) {
      console.log(error);
      console.log(error.response);
      return error.response;
    })
  }
  const getLanguageList = () => {
    ApiService.get('getLanguage').then(function (response) {
      setLanguage(response.data.data)
      if (getPlaylistList && getPlaylistList?.language.length > 0) {
        const matchedLanguage = response.data.data
          .filter((item) => getPlaylistList?.language.includes(item.language))
          .map((item) => item.id);
        setSelectedLanguage(matchedLanguage)
      }
    }).catch(function (error) {
      console.log(error);
      console.log(error.response);
      return error.response;
    })
  }
  const getGenreList = () => {
    getGenre().then(function (response) {
      setGeners(response.data.data)
      if (getPlaylistList?.playlist_genre?.length > 0) {
        const matchedGenres = response.data.data
          .filter((item) => getPlaylistList?.playlist_genre.includes(item.genre))
          .map((item) => item.id);
        setSelectedGenres(matchedGenres)
      }
    }).catch(function (error) {
      console.log(error);
      console.log(error.response);
      return error.response;
    })
  }
  const handleLanguageChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedLanguage(
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  const handleMoodChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedMood(
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  const handleGenreChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedGenres(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    if (name === 'playlist_name') {
      setPlaylistName(value);
      const charCount = value.length;
      const leadingSpacesRegex = /^\s+|\s+$/;

      if (leadingSpacesRegex.test(value)) {
        setBtnDisable(true);
        setPlatlistNameErr(true);
        setPlatlistNameErrMsg('Start and End spaces are not allowed.');
        return;
      }
      // Handle minimum length error
      if (charCount < 3) {
        setBtnDisable(true);
        setPlatlistNameErr(true);
        setPlatlistNameErrMsg('Minimum three characters are required.');
      } else if (charCount > 30) { // Handle maximum length error
        setBtnDisable(true);
        setPlatlistNameErr(true);
        setPlatlistNameErrMsg('Max limit is 30 characters.');
      } else {
        setBtnDisable(false);
        setPlatlistNameErr(false);
        setPlatlistNameErrMsg('');
      }
    }
    if (name == 'playlist_slug') {
      setPlaylistSlug(value);

    }
  };
  const handleSubmit = () => {
    // Validate playlist details, and create the card if valid
    if (playlistName && selectedPrivacy) {
      const selectedGenresId = selectedGenres
      const playlistDetails = {
        playlist_name: playlistName,
        playlist_genre: selectedGenresId.length ? selectedGenresId.join(',') : null,
        language: selectedLanguage.length ? selectedLanguage.join(',') : null,
        mood: selectedMood.length ? selectedMood.join(',') : null,
        type_official: 'official',
        playlist_cover_image: type != 'edit' ? selectedFile : getPlaylistList && getPlaylistList?.playlist_cover_image !== selectedFile ? selectedFile : null,
        slug: playlistSlug ? playlistSlug : null,
        privacy_status: selectedPrivacy === 'public',
      };

      var formData = new FormData()
      for (const key in playlistDetails) {
        if (playlistDetails.hasOwnProperty(key) && playlistDetails[key] !== null) {
          if (key === 'privacy_status') {
            formData.append(key, playlistDetails[key] === true ? true : false);
          }
          else
            formData.append(key, playlistDetails[key]);
        }
      }

      dispatch(setLoader(true))
      if (type !== 'edit') {
        ApiService.post('createEditPlaylist', formData).then((response) => {
          dispatch(setLoader(false))
          if (response.status == 200 || response.status == 201) {
            successSnack(formSnackbar.playlist.playlistCreate)
          }
        })
          .catch((error) => {
            dispatch(setLoader(false))
            errorSnack(formSnackbar.errors.errorMsg)
          })
      }
      else {
        ApiService.put('createEditPlaylist', formData, { id: getPlaylistList?.playlist_id }).then(function (response) {
          dispatch(setLoader(false))
          if (response.status === 200 || response.status === 201) {
            successSnack(formSnackbar.playlist.playlistUpdate)
            getSingleSong()
          }
        })
          .catch(function (error) {
            errorSnack(formSnackbar.errors.errorMsg)
          })
      }

      // Close the drawer
      handleClose();
    } else {
      setBtnDisable(true)
    }
  };

  return (
    <Drawer
      sx={{
        width: '400px',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: smallViewport ? '300px' : '400px',
          boxSizing: 'border-box',
          backgroundColor: '#ffffff',
        },
      }}
      anchor="right"
      open={isDrawerOpen}
      onClose={() => setIsDrawerOpen(false)}
    >
      <div>
        <div className="p-2 d-flex justify-content-between align-items-center" style={{ backgroundColor: 'rgb(248 248 248)' }}>
          <div style={{ marginLeft: '16px' }}><h4>{type !== 'edit' ? 'Create Official Playlist' : 'Edit Official Playlist'}</h4></div>
          <div style={{ position: 'relative', top: '-5px' }}>
            <button title="Close" className="icononly-btn" onClick={handleClose}>
              <CloseIcon />
            </button>
          </div>
        </div>
        <div className='p-4'>
          <div
            className="image-uploader "
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: 'none', }}
              ref={fileInputRef}
            />
            <div onClick={() => fileInputRef.current.click()} className="audio-uploader custom-height ">

              <p className='text-primary'><UploadFileIcon style={{ height: '2rem', width: '2rem' }} /></p>
              <div className='d-flex'>
                <div style={{ marginRight: '10px' }}>
                  <span className='text-primary'>Click to upload</span>
                </div>
                <div>
                  <span>or drag and drop</span>
                </div>
              </div>
              <p className='text-secondary' style={{ fontSize: '0.8rem' }}>SVG, PNG, JPG or GIF (max. 2MB)</p>
            </div>
            <div className="text-danger">
              {fileError}
            </div>

            {selectedFile && !fileSizeError && (
              <div className=" mt-3 mb-3" style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={type != 'edit' ? URL.createObjectURL(selectedFile) : getPlaylistList && getPlaylistList?.playlist_cover_image !== selectedFile ? URL.createObjectURL(selectedFile) : process.env.REACT_APP_CREATORURL_MEDIA + 'media/' + selectedFile} alt="Selected" style={{ width: '14rem', height: '14rem' }} />
              </div>
            )}
          </div>
          <div className='mb-3 mt-3'>
            <TextField
              size='small'
              name="playlist_name"
              label="Playlist name*"
              variant="outlined"
              className="w-100 rounded mt-2"
              value={playlistName}
              onChange={inputChangeHandler}
              error={playlistNameErr}
              helperText={playlistNameErr ? playlistNameErrMsg : null}
            />
          </div>
          <div className='mb-3'>
            <FormControl fullWidth size='small'>
              <InputLabel id="demo-multiple-checkbox-label">Select Genre</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedGenres}
                onChange={handleGenreChange}
                input={<OutlinedInput label="Select Genre" />}
                renderValue={(selected) => selected.map((value) => genres.find(genre => genre.id === value).genre).join(',')}
                MenuProps={MenuProps}
              >
                {genres && genres.length > 0 && genres.map((genre) => (
                  <MenuItem key={genre.id} value={genre.id}>
                    <Checkbox checked={selectedGenres.indexOf(genre.id) > -1} />
                    <ListItemText primary={genre.genre} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className='mb-3'>
            <FormControl fullWidth size='small'>
              <InputLabel id="demo-multiple-checkbox-label">Select Language</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedLanguage}
                onChange={handleLanguageChange}
                input={<OutlinedInput label="Select Language" />}
                renderValue={(selected) => selected.map((value) => language.find(language => language.id === value).language).join(',')}
                MenuProps={MenuProps}
              >
                {language && language.length > 0 && language.map((language) => (
                  <MenuItem key={language.id} value={language.id}>
                    <Checkbox checked={selectedLanguage.indexOf(language.id) > -1} />
                    <ListItemText primary={language.language} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className='mb-3'>
            <FormControl fullWidth size='small'>
              <InputLabel id="demo-multiple-checkbox-label">Select Mood</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedMood}
                onChange={handleMoodChange}
                input={<OutlinedInput label="Select Mood" />}
                renderValue={(selected) => selected.map((value) => mood.find(mood => mood.id === value).mood).join(',')}
                MenuProps={MenuProps}
              >
                {mood && mood.length > 0 && mood.map((mood) => (
                  <MenuItem key={mood.id} value={mood.id}>
                    <Checkbox checked={selectedMood.indexOf(mood.id) > -1} />
                    <ListItemText primary={mood.mood} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className='mb-3'>
            <FormControl sx={{ marginTop: '10px' }} fullWidth size='small' >
              <InputLabel >Playlist privacy*</InputLabel>
              <Select
                labelId="Playlist privacy"
                input={<OutlinedInput label="Playlist privacy *" />}
                value={selectedPrivacy}
                onChange={(e) => setSelectedPrivacy(e.target.value)}
                MenuProps={{
                  style: {
                    maxHeight: 250,
                  },
                }}
              >
                <MenuItem value="public">Public</MenuItem>
                <MenuItem value="private">Private</MenuItem>
              </Select>
            </FormControl>
          </div>
          {type !== 'edit' ? null : <div className='mb-3 mt-3'>
            <TextField
              size='small'
              name="playlist_slug"
              label="Slug"
              variant="outlined"
              className="w-100 rounded mt-2"
              value={playlistSlug}
              onChange={inputChangeHandler}
            />
          </div>}
          <div className='mt-4 d-flex justify-content-end '>
            <button className='gn-actionbtn' onClick={handleSubmit} disabled={disableBtn && selectedPrivacy}>Submit</button>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default PlaylistDrawer;
